<template>
  <div>
    <barra-crud :botaoNovo="true" :onFiltrar="(txt) => {filtro=txt}" :onNovo="() => {}" />
    <div class="text-center p-5" v-if="loading">
      <b-spinner large size="lg" /><br />
      carregando...
    </div>
    <div class="row" v-if="!loading">
      <div class="col-12">
        <b-table
          :items="lista"
          :current-page="currentPage"
          :per-page="perPage"
          small
          :filter="filtro"
          stacked="sm"
          :fields="[
            { key: 'cod_cliente', label: 'Código', sortable: true },
            { key: 'Nome', label: 'Nome', sortable: true },
            { key: 'Telefone', label: 'Telefone', sortable: true },
            { key: 'Endereco', label: 'Endereço', sortable: true },
            { key: 'Negativado', label: 'Negativado', sortable: true },
          ]"
        >
          <template #cell(Endereco)="row">
            <small>
              {{ row.item.Logradouro }}, {{ row.item.Numero }} -
              {{ row.item.Bairro }}
            </small>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BarraCrud from "../../../components/common/BarraCrud.vue";
import ClientesLib from "../../../libs/ClientesLib";
export default {
  components: {
    BarraCrud,
  },
  props: {},
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      lista: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      filtro:'',
    };
  },
  watch: {},
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;

      try {
        let res = await ClientesLib.getAllClientes(null);
        console.log("res clientes", res);
        if (res && res.success) {
          this.lista = res.data;
          this.totalRows = this.lista.length;
          this.currentPage = 1;
        }
      } catch (error) {
        console.log("ERROR ON GET CLIENTES", error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
