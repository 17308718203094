import axios from "@/utils/axios";

const getAllClientes = async (filters) => {
  let result = null;
  try {
    result = (await axios.post("/clientes/getAll", filters)).data;
  } catch (error) {
    console.log("error on getAllClientes", error);
  }
  return result;
}


export default {
  getAllClientes
}