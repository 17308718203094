var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('barra-crud',{attrs:{"botaoNovo":true,"onFiltrar":(txt) => {_vm.filtro=txt},"onNovo":() => {}}}),(_vm.loading)?_c('div',{staticClass:"text-center p-5"},[_c('b-spinner',{attrs:{"large":"","size":"lg"}}),_c('br'),_vm._v(" carregando... ")],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"items":_vm.lista,"current-page":_vm.currentPage,"per-page":_vm.perPage,"small":"","filter":_vm.filtro,"stacked":"sm","fields":[
          { key: 'cod_cliente', label: 'Código', sortable: true },
          { key: 'Nome', label: 'Nome', sortable: true },
          { key: 'Telefone', label: 'Telefone', sortable: true },
          { key: 'Endereco', label: 'Endereço', sortable: true },
          { key: 'Negativado', label: 'Negativado', sortable: true },
        ]},scopedSlots:_vm._u([{key:"cell(Endereco)",fn:function(row){return [_c('small',[_vm._v(" "+_vm._s(row.item.Logradouro)+", "+_vm._s(row.item.Numero)+" - "+_vm._s(row.item.Bairro)+" ")])]}}],null,false,83918057)}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }